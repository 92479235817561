.badge {
  font-size: 0.75rem;
  padding: 0.16rem 0.5rem 0.16rem 0.5rem;
  text-align: center;
  line-height: 1.75;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 2rem;

  &-adaptive {
    color: $gray-700;
    background-color: rgba($gray-700, 0.09);

    i {
      color: $gray-700 !important;
    }
  }

  i {
    font-size: 1.06667rem !important;
    padding-right: 0.3333rem !important;
  }
}
