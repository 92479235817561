// @import "~bootstrap/scss/bootstrap.scss";
// @import "./containers/Login/Login.scss";
@import "assets/scss/general.scss";
// body {
//   margin: 0;
//   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
//     "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
//     sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }
body {
  background-color: #f5f6fa;
  font-family: "Poppins";
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
button:disabled {
  cursor: default !important;
}
.re-arch-style {
  float: right;
  color: #eb1c1c;
  font-weight: 500;
  font-size: 1.135rem;
  animation: blinker 1s linear infinite;
}
.learnosity_assess-div {
  margin: 2% 2px 3% 2px;
}
.col-2_new {
  flex: 0 0 22%;
  max-width: 22%;
}
.col-1_new {
  flex: 0 0 11%;
  max-width: 11%;
}
.col-5_new {
  flex: 0 0 39%;
  max-width: 39%;
}

.antd-datepicker-disabled > .ant-picker-input > input:disabled {
  color: #000;
}
